import {
  FULL_WIDGET_CONTROLLER_ID,
  FULL_WIDGET_ID,
  getPresetNameById,
} from '../../constants';
import { PresetIds, PresetNames } from '../../enums';
import { EditorSDKUtils } from './editorSdkUtils';

export const setLoadMoreInPgPanel = async (manifest, editorSDK) => {
  const isHidden = await isHideLoadMore(editorSDK);

  manifest.controllersStageData[
    FULL_WIDGET_CONTROLLER_ID
  ].default.connections.proGallery1.panel = {
    ...manifest.controllersStageData[FULL_WIDGET_CONTROLLER_ID].default
      .connections.proGallery1.panel,
    layout: {
      controls: {
        behaviourParams_gallery_vertical_loadMore_enable: { hidden: isHidden },
      },
    },
  };
};

async function isHideLoadMore(editorSDK): Promise<boolean> {
  let isHidden = false;
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const allRefs = await editorSDKUtils.getAllAppRefComponents();

  const structures = await Promise.all(
    allRefs.map(async (ref) => {
      const structure = await editorSDKUtils.getCompStructure(ref);
      return structure;
    }),
  );

  await Promise.all(
    structures
      .filter(([ref]) => ref.data.widgetId === FULL_WIDGET_ID)
      .map(async ([{ componentRef }], _, arr) => {
        if (arr.length > 1) {
          console.log('setLoadMoreInPgPanel, More than one connected widget');
          return;
        }
        const preset = await editorSDKUtils.getPreset(componentRef);
        Object.values(PresetIds).forEach((presetId) => {
          if (presetId === preset.layout) {
            const presetName = getPresetNameById(presetId);
            if (presetName === PresetNames.Grid) {
              isHidden = true;
            }
          }
        });
      }),
  );

  return isHidden;
}
