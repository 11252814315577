import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { CustomSettingsPanel, PgCustomOnclick } from '../../enums';

export const configureCustomSettings = async (
  $w,
  flowAPI: PlatformControllerFlowAPI,
  isExpand = false,
) => {
  const { t } = flowAPI.translations;
  const translation = 'instagram-blocks.panel.pgSettings.dropdown';

  const filterOption = (opt) =>
    isExpand ? opt.value !== PgCustomOnclick.OpenExpand : true;

  const onClickOptions = [
    {
      label: t(`${translation}.options.openExpand`),
      value: PgCustomOnclick.OpenExpand,
    },
    {
      label: t(`${translation}.options.nothingHappens`),
      value: PgCustomOnclick.NothingHappens,
    },
    {
      label: t(`${translation}.options.openLink`),
      value: PgCustomOnclick.OpenLink,
    },
  ].filter(filterOption);

  const wixWidget = flowAPI.controllerConfig.wixCodeApi.widget;
  const props = await wixWidget?.getProps();
  const onClickAction = props?.onClickAction || PgCustomOnclick.OpenExpand;
  $w(CustomSettingsPanel.dropdown).placeholder = t(
    `${translation}.placeholder`,
  );
  $w(CustomSettingsPanel.dropdown).label = t(`${translation}.title`);
  $w(CustomSettingsPanel.dropdown).options = onClickOptions;
  $w(CustomSettingsPanel.dropdown).value = onClickAction;

  $w(CustomSettingsPanel.dropdown).onChange(async (e) => {
    const { value } = e.target;
    await wixWidget?.setProps({ onClickAction: value });
  });
};

export const getIsMobile = async (flowAPI) => {
  const viewport =
    await flowAPI.controllerConfig.wixCodeApi.editor.getCurrentViewport();
  return viewport.type === 'MOBILE';
};
