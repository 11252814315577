import {
  CollapsedExpandedComponentRef,
  ComponentRef,
  EventType,
  PageRef,
  PopupData,
  PresetValue,
  ViewportType,
} from '@wix/platform-editor-sdk';
import type { BehaviorObject } from '@wix/document-services-types';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { AddComponentOptions, OpenElementsPanel } from '../../types';

const TOKEN = 'token';
export class EditorSDKUtils {
  private editorSDK: FlowEditorSDK;

  constructor(editorSDK: FlowEditorSDK) {
    this.editorSDK = editorSDK;
  }

  async reloadManifest() {
    return this.editorSDK.document.application.reloadManifest();
  }

  async getCurrentPage() {
    return this.editorSDK.document.pages.getCurrent(TOKEN);
  }

  async addWidget(options) {
    return this.editorSDK.application.appStudioWidgets.addWidget(
      TOKEN,
      options,
    );
  }

  async getDocChildren(componentRef: PageRef): Promise<ComponentRef[]> {
    return this.editorSDK.document.components.getChildren(TOKEN, {
      componentRef,
    });
  }
  async getHost(componentRef: PageRef): Promise<ComponentRef> {
    return this.editorSDK.components.refComponents.getHostComponentRef(TOKEN, {
      componentRef,
    });
  }

  async addComponent(options: AddComponentOptions) {
    return this.editorSDK.components.add(TOKEN, options);
  }

  async setFullWidth(widgetRef: ComponentRef) {
    await this.editorSDK.document.components.setFullWidth(TOKEN, {
      componentRef: widgetRef,
      fullWidth: true,
    });
  }

  async addWidgetGfppClickListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.widgetGfppClicked,
      callback,
    );
  }

  async addComponentGfppClicked(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.componentGfppClicked,
      callback,
    );
  }

  async onAddWidgetListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.anyComponentAddedToStage,
      callback,
    );
  }

  async onDeleteWidgetListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.componentDeleted,
      callback,
    );
  }

  async sitePublishedListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.siteWasPublished,
      callback,
    );
  }

  async siteSavedListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(EventType.siteWasSaved, callback);
  }

  async getMetaSiteId(): Promise<string> {
    const msid = await this.editorSDK.info.getMetaSiteId(TOKEN);
    return msid;
  }

  async addGlobalDesignPresetChanged(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.globalDesignPresetChanged,
      callback,
    );
  }

  async removeAllOverrides(
    componentRef: ComponentRef,
  ): Promise<ComponentRef[]> {
    // @ts-expect-error
    return this.editorSDK.components.refComponents.removeAllOverrides(TOKEN, {
      componentRef,
    });
  }

  async changePreset(
    componentRef: ComponentRef,
    viewport: ViewportType,
    id: string,
  ): Promise<void> {
    return this.editorSDK.application.appStudioWidgets.changePreset(TOKEN, {
      context: { viewport },
      componentRef,
      stylePresetId: id,
      layoutPresetId: id,
    });
  }
  async openNativeComponentPanel({ componentRef, controls }) {
    return this.editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
      TOKEN,
      'settings',
      {
        componentRef,
        configuration: {
          controlGroups: {},
          controls,
          states: {},
        },
      },
    );
  }

  async openComponentPanel({
    componentRef,
    title,
    url,
    height = 198,
    width = 288,
    initialData = {},
    helpId = '',
  }: {
    componentRef: ComponentRef;
    title: string;
    url: string;
    height?: number;
    width?: number;
    initialData?: Record<string, any>;
    helpId: string;
  }) {
    return this.editorSDK.editor.openComponentPanel(
      TOKEN,
      {
        title,
        url,
        height,
        width,
        componentRef,
        initialData: {
          componentRef,
          ...initialData,
        },
        helpId,
      },
      (token) => {
        this.editorSDK.editor.showPanelPreloader(token);
      },
    );
  }

  async getPreset(componentRef: ComponentRef): Promise<PresetValue> {
    const preset =
      await this.editorSDK.document.application.appStudioWidgets.getPreset(
        TOKEN,
        { componentRef },
      );
    return preset;
  }

  async getAncestors(componentRef: ComponentRef): Promise<ComponentRef[]> {
    return this.editorSDK.components.getAncestors(TOKEN, {
      componentRef,
    });
  }

  async getAllAppRefComponents() {
    return this.editorSDK.components.refComponents.getAllAppRefComponents(
      TOKEN,
    );
  }

  async getCollapsedRefComponents(
    componentRef: ComponentRef,
    includeInnerCollapsed: boolean,
  ): Promise<CollapsedExpandedComponentRef[]> {
    return this.editorSDK.components.refComponents.getCollapsedRefComponents(
      TOKEN,
      {
        componentRef,
        // @ts-expect-error temp until types are GAed
        includeInnerCollapsed,
      },
    );
  }

  async getChildren(componentRef: ComponentRef): Promise<any> {
    const children = await this.editorSDK.components.getChildren(TOKEN, {
      componentRef,
    });
    return children;
  }

  async getType(componentRef: ComponentRef): Promise<string> {
    return this.editorSDK.components.getType(TOKEN, {
      componentRef,
    });
  }

  async expandReferredComponent(componentRef: ComponentRef): Promise<boolean> {
    return this.editorSDK.components.refComponents.expandReferredComponent(
      TOKEN,
      {
        componentRef,
      },
    );
  }

  async refreshLivePreview(
    shouldFetchData: boolean,
    source: string,
  ): Promise<any> {
    return this.editorSDK.application.livePreview.refresh(TOKEN, {
      shouldFetchData,
      source,
    });
  }

  async setWidgetHeight(
    componentRef: ComponentRef,
    height: number,
  ): Promise<void> {
    await this.editorSDK.document.components.layout.update('', {
      componentRef,
      layout: {
        height,
      },
    });
  }

  async collapseReferredComponent(componentRef: ComponentRef): Promise<void> {
    this.editorSDK.components.refComponents.collapseReferredComponent(TOKEN, {
      componentRef,
    });
  }

  async findAllByRole(controllerRef: ComponentRef, role: string): Promise<any> {
    return this.editorSDK.document.components.findAllByRole(TOKEN, {
      controllerRef,
      role,
    });
  }

  async setControllerState(controllerRef: ComponentRef, state): Promise<void> {
    await this.editorSDK.controllers.setState('', {
      state: {
        [state]: [controllerRef],
      },
    });
  }

  async getMultistateBoxRef(): Promise<ComponentRef> {
    const componentType = 'wixui.MultiStateBox';
    const [componentRef] = await this.editorSDK.components.findAllByType(
      TOKEN,
      {
        componentType,
      },
    );
    if (!componentRef) {
      throw new Error('Could not find MultiStateBox component');
    }
    return componentRef;
  }

  async getComponentData(componentRef: ComponentRef): Promise<any> {
    return this.editorSDK.components.data.get(TOKEN, { componentRef });
  }

  async getInstance(): Promise<string> {
    return this.editorSDK.document.info.getAppInstance(TOKEN);
  }

  async getInstanceId(): Promise<string> {
    return this.editorSDK.document.info.getAppInstanceId(TOKEN);
  }

  async openPagesPanel(): Promise<void> {
    const { check, show } = this.editorSDK.editor.deeplink;
    const isDeeplink = await check(TOKEN, { type: 'pagesPanel', params: [] });
    if (isDeeplink) {
      await show(TOKEN, { type: 'pagesPanel', params: [] });
    }
  }

  async navigateToLightbox(id: string): Promise<void> {
    await this.editorSDK.document.pages.navigateTo(TOKEN, {
      pageRef: { type: 'DESKTOP', id },
    });
  }

  async getLighboxes(): Promise<PopupData[]> {
    const lightboxes =
      await this.editorSDK.document.pages.popupPages.getApplicationPopups(
        TOKEN,
      );
    return lightboxes;
  }

  async removeLightbox(id): Promise<void> {
    await this.editorSDK.document.pages.remove(TOKEN, {
      pageRef: { id, type: 'DESKTOP' },
      shouldShowEditorRemovePanel: false,
    });
  }

  async getAllPages(): Promise<any[]> {
    const applicationPages =
      await this.editorSDK.document.pages.getApplicationPages(TOKEN);

    return applicationPages;
  }

  // async getLighboxDataList(): Promise<any> {
  //   // const lightboxes = await this.editorSDK.document.pages.popupPages.getDataList(TOKEN);
  //   return lightboxes;
  // }

  async uninstallApplication(): Promise<void> {
    await this.editorSDK.application.uninstall('', { openConfirmation: false });
  }

  async navigateToPopup(popupRef: PageRef): Promise<void> {
    await this.editorSDK.document.pages.popupPages.open(TOKEN, { popupRef });
  }

  async addPopupPage(options): Promise<PageRef> {
    return this.editorSDK.document.pages.popupPages.add(TOKEN, options);
  }

  async disablePopupsAutoOpen() {
    const mainPageRef: ComponentRef = await this.editorSDK.pages.getPrimary(
      TOKEN,
    );
    const behaviors = (await this.editorSDK.components.behaviors.get(TOKEN, {
      componentRef: mainPageRef,
    })) as BehaviorObject[];

    const autoOpenBehaviors = behaviors?.filter(
      (behavior) =>
        behavior.action.name === 'load' &&
        behavior.behavior.name === 'openPopup',
    );

    autoOpenBehaviors?.forEach(async (behavior) => {
      // eslint-disable-next-line no-console
      console.log('IG on blocks - removing auto open behavior: ', behavior);

      await this.editorSDK.components.behaviors.remove(TOKEN, {
        componentRef: mainPageRef,
        // @ts-expect-error object type is valid here - Document Management also accepts it: https://github.com/wix-private/document-management/blob/340ef4a8c29ef22503fd3303fa4e60dd775c4362/document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts#L334
        behaviorName: behavior,
      });
    });
  }

  async setWidgetProps({
    componentRef,
    newProps,
  }: {
    componentRef: ComponentRef;
    newProps: WidgetProps;
  }): Promise<void> {
    await this.editorSDK?.document.application.appStudioWidgets.props.set(
      TOKEN,
      {
        widgetRef: componentRef,
        newProps,
      },
    );
  }

  async getWidgetProps(componentRef: ComponentRef): Promise<any> {
    const props = await this.editorSDK?.application.appStudioWidgets.props.get(
      TOKEN,
      { widgetRef: componentRef },
    );
    return props;
  }

  async openDashboard({ url }) {
    await this.editorSDK.editor.openDashboardPanel(TOKEN, {
      url,
      closeOtherPanels: true,
    });
  }

  async getPublicUrl() {
    const publicURL = await this.editorSDK?.document.info.getPublicUrl(TOKEN);
    return publicURL;
  }

  async removeElementFromPanel({ componentRef, role }): Promise<void> {
    try {
      const [controllerRef] = await this.getChildren(componentRef);
      const [elementRef] = await this.findAllByRole(controllerRef, role);
      await this.collapseReferredComponent(elementRef);
    } catch (e) {
      console.error('removeElementFromPanel', { e });
    }
  }

  async openStylableButtonMenu(
    componentRef,
    isLabelHidden = false,
  ): Promise<any> {
    this.editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
      TOKEN,
      'settings',
      {
        componentRef,
        configuration: {
          controls: {
            link: { hidden: true },
            label: { hidden: isLabelHidden },
            icon: {},
          },
          controlGroups: {},
          states: {},
        },
      },
    );
  }

  async updateComponent(componentRef, data): Promise<void> {
    await this.editorSDK.components.data.update(TOKEN, {
      componentRef,
      data,
    });
  }

  async getCompStructure(componentRefs): Promise<any> {
    const properties = [
      'data',
      'props',
      'componentType',
      'sdkType',
      'connections',
      'skin',
      'style',
      'role',
    ];
    const compStructure = await this.editorSDK.components.get(TOKEN, {
      componentRefs,
      properties,
    });
    return compStructure;
  }

  async openElementsPanel({
    widgetRef,
    elementsData,
    categoriesData,
    addComponentHandler,
    removeComponentHandler,
  }: OpenElementsPanel): Promise<void> {
    this.editorSDK.editor.openElementsPanel(TOKEN, {
      widgetRef,
      categoriesData,
      elementsData,
      addComponentHandler,
      removeComponentHandler,
    });
  }
}
