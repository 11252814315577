import { EditorReadyOptions, EditorType } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
// import { bi } from '../bi';
import { EditorSDKUtils } from './editorSdkUtils';
import { presetListenerCallback } from './presetListenerCallback';
import { createLightboxWithWidget, navigateToLightboxEditor } from './lightbox';
import { createGfppActions } from './gfppUtils';
import { FULL_WIDGET_ID, INITIAL_PROPS } from '../../constants';
import { onWidgetDeletion } from './onLastWidgetDeletion';
import { initDeviceSwitchEventListener } from './onDeviceChanged';

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const { t } = flowAPI.translations;
  await initDeviceSwitchEventListener({ editorSDK });

  await editorSDKUtils.addComponentGfppClicked(async (event) => {
    const { id, componentRef } = event.detail;
    const {
      openPgCustomSetting,
      openExpandSettings,
      openAccountSettings,
      openButtonPanel,
    } = await createGfppActions({
      editorSDK,
      componentRef,
      options,
      flowAPI,
    });
    switch (id) {
      case 'openButtonWithoutText':
        return openButtonPanel();
      case 'openSettingsPanel':
        return openAccountSettings(true);
      case 'openCustomSettings':
        await openPgCustomSetting();
        break;
      case 'settingsPGExpand':
        await openExpandSettings();
        break;
      case 'openEditorLightbox':
        await navigateToLightboxEditor(editorSDK);
        break;
      default:
        console.log('Could not find right comp gfpp', { id, componentRef });
    }
  });

  await editorSDKUtils.addWidgetGfppClickListener(async (event) => {
    const { id, componentRef } = event.detail;
    // const msid = await editorSDKUtils.getMetaSiteId();
    // const instance_id = await editorSDKUtils.getInstanceId();
    // const biLogger = bi({ flowAPI, msid, instance_id });
    // const biBtnClick = biLogger.sitePromoButtonClick;
    const { openAccountSettings, openElements, openLayout } =
      await createGfppActions({
        editorSDK,
        componentRef,
        options,
        flowAPI,
      });

    switch (id) {
      case 'openSettingsPanel':
        return openAccountSettings();
      case 'openElementsPanel':
        return openElements({ t });
      // biBtnClick('widget_elements');
      case 'openLayoutPanel':
        return openLayout();
      // biBtnClick('widget_layout');
      default:
        console.log('Could not find right gfpp', { id, componentRef });
    }
  });

  await editorSDKUtils.onDeleteWidgetListener(async () => {
    return onWidgetDeletion(editorSDKUtils);
  });

  await editorSDKUtils.onAddWidgetListener(async (event) => {
    const { compRef } = event.detail;
    const [compStructure] = await editorSDKUtils.getCompStructure(compRef);
    const isResponsive = options.origin.type === EditorType.Responsive;
    if (compStructure?.data?.widgetId === FULL_WIDGET_ID) {
      setMainWidgetProps();
    }

    return createLightboxWithWidget(editorSDK, isResponsive, t);

    async function setMainWidgetProps() {
      const MAX_RETRIES = 10;
      let retries = 0;

      async function trySetProps() {
        const [childRef] = await editorSDKUtils.getChildren(compRef);

        if (childRef) {
          await editorSDKUtils.setWidgetProps({
            componentRef: childRef,
            newProps: { ...INITIAL_PROPS, isClassic: !isResponsive },
          });
        } else if (retries < MAX_RETRIES) {
          retries++;
          await new Promise((resolve) => setTimeout(resolve, 300));
          await trySetProps();
        } else {
          console.error('Max retries reached. Unable to set widget props.');
        }
      }

      await trySetProps();
    }
  });

  await editorSDKUtils.addGlobalDesignPresetChanged(async (event) => {
    return presetListenerCallback({ event, editorSDK, flowAPI });
  });
};
