import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { ElementRoles, HeaderStates } from '../../enums';
import { mainWidgetPresets, HELP_ID } from '../../constants';
import {
  EditorSDKUtils,
  globalDesignBuilder,
  preventHide,
} from '../../utils/Editor';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('instagram-blocks.widget.displayName'),
    nickname: t('instagram-blocks.widget.nickName'),
  });

  widgetBuilder
    .gfpp()
    .set('settings', { behavior: 'HIDE' })
    .set('mainAction1', {
      label: t('instagram-blocks.widget.gfpp.mainAction1'),
      actionId: 'openSettingsPanel',
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('help', { id: HELP_ID });

  widgetBuilder.configureConnectedComponents(
    ElementRoles.ProGallery,
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('instagram-blocks.widget.gfpp.mainAction1'),
          actionId: 'openSettingsPanel',
        })
        .set('mainAction2', {
          label: t('instagram-blocks.widget.pg.gfpp.openExpandPreview'),
          actionId: 'openEditorLightbox',
        })
        .set('settings', {
          actionId: 'openCustomSettings',
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    ElementRoles.HeaderUserNameBtn,
    (connectedComponentsBuilder) => {

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('instagram-blocks.expand.gfpp.mainAction1'),
          actionId: 'openButtonWithoutText',
        })
        .set('link', { behavior: 'DEFAULT' })
        .set('connect', { behavior: 'HIDE' });
    },
  );

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('settings', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(
    ElementRoles.ProGallery,
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction1', { behavior: 'HIDE' })
        .set('mainAction2', {
          label: t('instagram-blocks.widget.pg.gfpp.openExpandPreview'),
          actionId: 'openEditorLightbox',
        })
        .set('settings', { behavior: 'HIDE' })
        // NOT WORKING
        .set('hide', { behavior: 'HIDE' });
    },
  );

  // Make emptyState widget non-selectable
  widgetBuilder.configureConnectedComponents(
    ElementRoles.EmptyStateWidget,
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
    },
  );

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('instagram-blocks.panel.presets.title'),
      presetsTitle: t('instagram-blocks.panel.presets.presetsTitle'),
    });
    globalDesignBuilder(widgetDesignBuilder, t);
  });

  // Disable inner header elements on panel
  preventHide(widgetBuilder, false);
  widgetBuilder.configureState(HeaderStates.State2, (stateBuilder) => {
    preventHide(stateBuilder);
  });
};

export const exports = (editorSDK: FlowEditorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      const editorSDKUtils = new EditorSDKUtils(editorSDK);
      const selectedPreset = await editorSDKUtils.getPreset(widgetRef);
      return selectedPreset.style;
    },
  },
});
